// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-album-pages-js": () => import("./../../../src/pages/albumPages.js" /* webpackChunkName: "component---src-pages-album-pages-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gallery-sanity-albums-slug-current-js": () => import("./../../../src/pages/gallery/{SanityAlbums.slug__current}.js" /* webpackChunkName: "component---src-pages-gallery-sanity-albums-slug-current-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifestyle-js": () => import("./../../../src/pages/lifestyle.js" /* webpackChunkName: "component---src-pages-lifestyle-js" */),
  "component---src-pages-lifestyle-services-js": () => import("./../../../src/pages/lifestyle/services.js" /* webpackChunkName: "component---src-pages-lifestyle-services-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-opportunities-js": () => import("./../../../src/pages/opportunities.js" /* webpackChunkName: "component---src-pages-opportunities-js" */),
  "component---src-pages-podcasts-dr-mike-kirlew-presents-js": () => import("./../../../src/pages/podcasts/dr-mike-kirlew-presents.js" /* webpackChunkName: "component---src-pages-podcasts-dr-mike-kirlew-presents-js" */),
  "component---src-pages-podcasts-gem-js": () => import("./../../../src/pages/podcasts/gem.js" /* webpackChunkName: "component---src-pages-podcasts-gem-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-job-post-template-js": () => import("./../../../src/templates/JobPostTemplate.js" /* webpackChunkName: "component---src-templates-job-post-template-js" */)
}

